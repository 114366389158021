.mainWrapper {
    display: flex;
    .sidebarWrapper {

    }
    .contentWrapper {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}


.mainComponentLogin {
    background: #05050F;
    flex: 1;
    .mainComponent {
        height: 100%;
        padding: 24px 50px 50px 50px;
        display: flex;
        flex-direction: column;
        .headerAction {
            text-align: right;
            margin-bottom: 24px;
        }
       .tableWrapper {
        margin-top: 100px;
           gap: 36px;
           flex: 1;
           .tableBox {
            display: flex;
            flex-direction: column;
            width: 100%;
           }
           .pagination {
               color: #fff;
               margin-top: 20px;
               ul {
                   list-style-type: none;
                   li {
                    display: inline-block;
                    font-size: 20px;
                    margin: 0 5px;
                    padding: 0 5px;
                    cursor: pointer;
                   }
                   li.active {
                       color: rgb(137, 137, 137);
                       cursor: initial;
                   }
               }
           }
           .editUserWrapper {
                background: #121222;
                box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
                border-radius: 36px;
                min-width: 486px;
                display: flex;
                flex-direction: column;
                .editUserTitle {
                    background: #161628;
                    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: #FFFFFF;
                    padding: 34px 36px 27px 36px;
                    border-bottom: 1px solid #303041;
                    border-radius: 36px 36px 0 0;
                }
                .editUserBox {
                    flex: 1;
                    padding: 36px;
                    display: flex;
                    flex-direction: column;
                    .editUserInput {
                        margin-bottom: 24px;
                    }
                    .editUserSelect {
                        margin-bottom: 56px;
                    }
                    .buttonsWrapper {
                        display: flex;
                        gap: 16px;
                        margin-top: auto;
                    }
                }
           }
           table {
                background: rgba(90, 90, 137, 0.2);
                box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
                border-radius: 36px;
                width: 100%;
                border-spacing: 0px;
                overflow: hidden;
                flex: 1;
               thead {
                    background: #161628;
                    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
                   tr {
                       th {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #FFFFFF;
                        padding: 24px;
                        text-align: left;
                        &:last-child {
                            text-align: right;
                           }
                       }
                       
                   }
               }
               tbody {
                background: #121222;
                   tr {
                       height: 52px;
                       td {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #FFFFFF;
                        opacity: 0.5;
                        padding: 18px 24px;
                            &:last-child {
                                text-align: right;
                            }
                        }
                        td.tableEdit {
                            cursor: pointer;
                            img {
                                vertical-align: middle;
                                margin-right: 4px;
                            }
                        }
                   }
                   tr.rowSelected {
                    background: #0e4635;
                   }
                   tr.empty {
                       height: auto !important;
                       margin: auto;
                   }
               }
           }
       }
    }
}
