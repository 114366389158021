.mainComponentLogin {
    background: #05050F;
    .mainComponent {
        display: flex;
        min-height: 100vh;
        .logoWrapper {
            width: 100%;
            margin: auto;
            text-align: center;
        }
        .loginFormWrapper {
            width: 100%;
            margin: auto;
            .loginFormBox {
                background: rgba(90, 90, 137, 0.2);
                border-radius: 36px;
                width: 600px;
                padding: 100px 100px 50px 100px;
                .loginFormTitle {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 42px;
                    line-height: 51px;
                    color: #FFFFFF;
                    margin-bottom: 50px;
                }
                .loginFormInputUsername {
                    margin-bottom: 36px;
                }
                .forgotPasswordWrapper {
                    text-align: right;
                    margin-top: 8px;
                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.02em;
                        text-decoration-line: underline;
                        color: rgba(255, 255, 255, 0.4);
                        cursor: pointer;
                    }
                }
                .rememberWrapper {
                    margin-top: 10px;
                }
                .buttonWrapper {
                    margin-top: 84px;
                }
            }
        }
    }
}

@media(max-width: 1600px) {
    .mainComponentLogin {
        .mainComponent {
            .logoWrapper {
                img {
                    width: 500px;
                }
            }
        }
    }
}

@media(max-width: 1250px) {
    .mainComponentLogin {
        .mainComponent {
            .logoWrapper {
                img {
                    width: 350px;
                }
            }
            .loginFormWrapper {
                .loginFormBox {
                    border-radius: 20px;
                    width: 400px;
                    padding: 40px;
                }
            }
        }
    }
}

@media(max-width: 840px) {
    .mainComponentLogin {
        .mainComponent {
            flex-direction: column;
            .logoWrapper {
                margin: 30px auto;
            }
            .loginFormWrapper {
                margin: 30px auto;
                .loginFormBox {
                    margin: auto;
                }
            }
        }
    }
}

