    @media only screen and (max-width: 970px) {
        div.contentWrapper {
          margin-top: 150px;
        }
      }
.icons{
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
    width: 100%;
    justify-content: space-between;
}

.ag-ltr .ag-cell {
  align-items: center;
  height: 100%;
  border-right-width: 1px;
  display: flex;
}
.ag-body-viewport{
    min-height: 100px;
}

.multiSelect .select__control {
    height: 52px;
    border-radius: 12px;
    width: 100%;
    padding: 12px 16px;
    background: #1d1d32;
    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
    border-radius: 12px;
    display: inline-block;
    margin-top: 8px;
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAYAAAACTR1pAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABVSURBVHgBjdHBCQAgCAVQR2iERnA0R2hjRzANDxGaCkLkfxEIIjK0SXtCUZpB7WUG/GDFNigQe5beixAHmZkN8IOwehU7v8lwjRLcQwHuowuPs6+kNvEE5d92EV/LAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 97% 50%;
    color: #a5a5ad;
}
.multiSelect .select__indicators{
    display: none;
}
.multiSelect .select__menu{
    color: #a5a5ad;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAYAAAACTR1pAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABVSURBVHgBjdHBCQAgCAVQR2iERnA0R2hjRzANDxGaCkLkfxEIIjK0SXtCUZpB7WUG/GDFNigQe5beixAHmZkN8IOwehU7v8lwjRLcQwHuowuPs6+kNvEE5d92EV/LAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background: #1d1d32;
}
