.popupWrapper {
    background: rgba(5, 5, 15, 0.6);
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    .popupBox {
        background: #121222;
        border-radius: 36px;
        width: 624px;
        margin: auto;
        border: 1px solid #282836;
        padding: 52px 52px 48px 52px;
        .popupHeader {
            display: flex;
            margin-bottom: 36px;
            .popupName {
                flex: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 39px;
                color: #FFFFFF;
            }
            .popupX {
                margin: auto;
                img {
                    cursor: pointer;
                }
            }
        }
        .popupForm {
            padding-bottom: 28px;
            .popupFormItem {
                margin-bottom: 24px;
            }
        }
        .popupButtonsWrapper {
            display: flex;
            gap: 16px;
            width: 351px;
            margin-left: auto;
        }
    }
}