.formButton {
    background: rgba(90, 90, 137, 0.2);
    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
    border-radius: 36px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: none;
    padding: 15px 24px;
    cursor: pointer;
    img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        vertical-align: middle;
    }
}
.green {
    background: linear-gradient(93.3deg, rgba(0, 191, 87, 0.3) 1.31%, rgba(0, 93, 105, 0.3) 98.88%, rgba(0, 105, 86, 0.3) 98.88%);
    border: 1px solid rgba(0, 198, 162, 0.7);
    border-radius: 12px;
    img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        vertical-align: middle;
    }
}

.grey {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.52);
    border-radius: 12px;
}

.red {
    background: linear-gradient(93.3deg, rgba(255, 71, 71, 0.4) 1.31%, rgba(132, 15, 15, 0.4) 98.88%);
    border-radius: 12px;
}

.transparent {
    background: transparent;
    border: 1px solid rgba(0, 198, 162, 0.7);
    border-radius: 12px;
    img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        vertical-align: middle;
    }
}