@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;

}

.container-code-editor__qxcy .code-editor__qxcy > * {
    font-family: 'Montserrat', sans-serif !important;
}
.ag-theme-alpine-dark {
    --ag-header-background-color: #23242e !important;
    --ag-border-color:transparent !important;
    --ag-row-hover-color:#0d3a2f !important;
    --ag-header-height: 65px !important;
}