
div.ag-root-wrapper.ag-layout-normal {
    border: none !important;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
}

.roleNavigate{
    display: flex;
    align-items: center;
}
.roleNav{
    cursor: pointer;
    text-wrap: nowrap;
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    Opacity:60%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin-right: 28px;
    padding-bottom: 8px;
    border-bottom: 8px;
    border-color: #10111C;

}

.ActiverRole{
    cursor: pointer;
    text-wrap: nowrap;
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin-right: 28px;
    padding-bottom: 8px;
    border-bottom: ridge;
    border-color: #00BF57;

}

.tableHeader {

    display: flex;
    padding: 24px 0;
}
.tableHeaderButton{
    justify-content: end;
    width: 681px;
    display: flex;
    flex-direction: row;
}
.tableHeaderText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    flex: 1;
    margin: auto;
}
.tableHeaderButton label {
    margin-right: 20px;
}

.tableHeaderButton label select {
    margin-top: 0;
}
.tableHeaderButton button {
    width: 100%;
}

div.ag-root-wrapper.ag-layout-normal{
    /*height: 500px;*/
}

div.ag-root-wrapper-body.ag-layout-normal{
    height: auto;
}

.ag-theme-alpine .ag-row, .ag-theme-alpine-dark .ag-row{
    height: 60px;
    background-color: #121222;
}

.ag-center-cols-viewport{
    background-color: #121222;
}
.ag-paging-panel{
    background-color: #121222;
}
.ag-header {
    height: 68px;
}
.ag-theme-alpine-dark .ag-row:not(.ag-row-hover){
    opacity: 0.5;
}
.ag-theme-alpine-dark .ag-cell:last-child{
    background: #22232e ;
}
.ag-theme-alpine-dark .ag-row-hover .ag-cell:last-child{
    background: #11232c ;
}

