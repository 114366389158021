.formSelect {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    width: 100%;
    select {
        height: 52px;
        border-radius: 12px;
        width: 100%;
        padding: 12px 16px;
        background: #1d1d32;
        box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
        border-radius: 12px;
        display: inline-block;
        margin-top: 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('./Vector.png');
        background-repeat: no-repeat;
        background-position: 97% 50%;
        color: #a5a5ad;
    }
   
}
