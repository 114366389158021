.headerWrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    background: #05050f;
    display: flex;
    .headerTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        padding: 36px 36px 36px 50px;
    }
    .headerActionWrapper {
        flex: 1;
        text-align: right;
        padding: 23px 50px 23px 23px;
        .headerSearch {
            position: relative;
            display: inline-block;
            vertical-align: super;
            img {
                position: absolute;
                left: 22px;
                top: 21px;
            }
            input {
                background: #121222;
                box-shadow: 0px 2px 6px rgb(51 51 51 / 11%);
                border-radius: 36px;
                border: 1px solid #2e2e38;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.48);
                padding: 16px 20px 16px 48px;
                width: 532px;
                display: inline-block;
            }
        }
        .headerNotification, .headerSettings, .headerProfile {
            display: inline-block;
            background: #121222;
            box-shadow: 0px 2px 6px rgb(51 51 51 / 11%);
            border-radius: 36px;
            border: 1px solid #2c2c36;
            width: 54px;
            height: 54px;
            text-align: center;
            padding: 15px;
            cursor: pointer;
            margin-left: 20px;
        }
        .headerSettings {

        }
        .headerProfile {

        }
    }
}