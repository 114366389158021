.mainWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .contentWrapper {
    width: 100%;
    margin-top: 77px;
    flex: 1;
    display: flex;
    gap: 8px;
    padding: 12px 12px 12px 0px;
    background: #05050f;

    .tableWrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      zoom:0.8;

      .div.ag-root-wrapper.ag-layout-normal {
        min-height: 500px;
      }
    }

    .table-Wrapper {
      gap: 12px;
      overflow: auto;
      background: #10111c;
      border: 1px solid hsla(0, 0%, 100%, 0.08);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 20px;
      flex: 1 1;
      margin-left: 6px;
      margin-top: 1px;

      .editWrapper {
        background: #10111c;
        box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
        border-radius: 12px;
        min-width: 442px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: calc(100vh - 90px);
        position: fixed;
        z-index: 7;
        top: 0;
        margin-top: 90px;
        right: 0;

        .editHeader {
          display: flex;
          padding: 34px 16px 27px 16px;

          .editTitle {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
            // padding: 34px 36px 27px 36px;
            flex: 1;
          }

          .close {
            background: #23242e;
            border-radius: 36px;
            width: 36px;
            height: 36px;
            padding: 9px 0;
            text-align: center;
            cursor: pointer;
          }
        }

        .settingsAction {
          text-align: right;
          padding: 24px 16px;
          gap: 16px;
          display: flex;
        }

        .editBox {
          flex: 1;
          padding: 0 12px;
          display: flex;
          flex-direction: column;

          .infoList {
            background: #1a1b25;
            list-style-type: none;
            border: 1px solid rgba(255, 255, 255, 0.04);
            border-radius: 12px;
            padding: 0 16px;
            margin-bottom: 16px;

            li {
              border-bottom: 1px solid #2c2d36;
              padding: 16px 0;
              display: flex;

              .infoListName {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
                opacity: 0.65;
                flex: 1;
              }

              .infoListValue {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }

        hr {
          border: 1px solid #FFFFFF14
        }

        .searchPanel {
          padding-top: 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;

          h4 {
            width: 64px;
            height: 20px;
            top: 522px;
            left: 12px;
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;


          }

          .VersionSearchheaderSearch {
            position: relative;
            display: inline-block;
            vertical-align: super;
            align-items: center;

            img {
              position: absolute;
              left: 18px;
              top: 15px;
            }

            input {
              background: #1a1b25;
              box-shadow: 0px 2px 6px rgb(51 51 51 / 11%);
              border-radius: 36px;
              border: 1px solid #2e2e38;
              font-style: normal;
              height: 40px;
              width: 152px;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              text-align: left;
              color: rgba(255, 255, 255, 0.48);
              padding: 16px 20px 16px 48px;
              display: inline-block;

            }

            input::placeholder {
              font-size: 11px;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

          }
        }

        .apiBox {
          background: #1a1b25;
          list-style-type: none;
          border: 1px solid rgba(255, 255, 255, 0.04);
          border-radius: 12px;
          padding: 0 16px;
          margin-bottom: 12px;
          padding: 8px 16px 0 16px;

          li {
            padding: 8px 0 16px 0;

            .apiLabel {
              display: flex;
              margin-bottom: 12px;

              .apiLabelName {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
                opacity: 0.65;
                flex: 1;
              }

              .apiLabelbutton {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.02em;
                text-decoration-line: underline;
                color: #00C6A2;
                cursor: pointer;
              }
            }

            .apiValue {
              display: flex;
              background: #23242e;
              border-radius: 8px;

              .apiValueText {
                flex: 1;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
                padding: 12px;
              }

              .apiValueAction {
                background: #35363f;
                padding: 8px;
                width: 40px;
                text-align: center;
                border-radius: 8px;

                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

}

.Role-Table-Wrapper {
  gap: 12px;
  overflow: auto;
  background: #10111c;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1;
  margin-left: 6px;
  margin-top: 1px;

  .editWrapper {
    background: #10111c;
    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
    border-radius: 12px;
    max-width: 442px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100vh - 90px);
    position: fixed;
    z-index: 7;
    top: 0;
    margin-top: 90px;
    right: 0;

    .editHeader {
      display: flex;
      padding: 34px 16px 27px 16px;

      .editTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        // padding: 34px 36px 27px 36px;
        flex: 1;
      }

      .close {
        background: #23242e;
        border-radius: 36px;
        width: 36px;
        height: 36px;
        padding: 9px 0;
        text-align: center;
        cursor: pointer;
      }
    }

    .settingsAction {
      text-align: right;
      padding: 24px 16px;
      gap: 16px;
      display: flex;
    }

    .editBox {
      flex: 1;
      padding: 0 12px;
      display: flex;
      flex-direction: column;

      .infoList {
        background: #1a1b25;
        list-style-type: none;
        border: 1px solid rgba(255, 255, 255, 0.04);
        border-radius: 12px;
        padding: 0 16px;
        margin-bottom: 12px;

        li {
          border-bottom: 1px solid #2c2d36;
          padding: 16px 0;
          display: flex;

          .infoListName {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
            opacity: 0.65;
            flex: 1;
          }

          .infoListValue {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .apiBox {
      background: #1a1b25;
      list-style-type: none;
      border: 1px solid rgba(255, 255, 255, 0.04);
      border-radius: 12px;
      padding: 0 16px;
      margin-bottom: 12px;
      padding: 8px 16px 0 16px;

      li {
        padding: 8px 0 16px 0;

        .apiLabel {
          display: flex;
          margin-bottom: 12px;

          .apiLabelName {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
            opacity: 0.65;
            flex: 1;
          }

          .apiLabelbutton {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.02em;
            text-decoration-line: underline;
            color: #00C6A2;
            cursor: pointer;
          }
        }

        .apiValue {
          display: flex;
          background: #23242e;
          border-radius: 8px;

          .apiValueText {
            flex: 1;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
            padding: 12px;
          }

          .apiValueAction {
            background: #35363f;
            padding: 8px;
            width: 40px;
            text-align: center;
            border-radius: 8px;

            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 42px;
  height: 22px;
  background: #292626;
  border: 1px solid #FFFFFF7A;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.switch-label .switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 10px;
}

.infoListActions {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  img {
    padding-left: 12px;
    cursor: pointer;
  }
}

.infoListNameRole {
  height: 17px;
  opacity: 80%;
  color: #FFFFFF;
}

.infoListRole {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.rollBtns {
  display: flex;
  height: 52px;

  div {
    padding-right: 16px;
    width: 135px;
  }
}

.settingsActionRole {
  padding-top: 36px;
  padding-bottom: 36px;
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.apiLabelbuttonRole {
  padding-right: 18px;
  text-align: end;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #00C6A2;
  cursor: pointer;
  display: contents;
  padding-bottom: 10px;
}

.addBtnConTainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 16px;
  padding-bottom: 7px;
}
