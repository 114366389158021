.segmentNodePoints {
    display: flex;
    justify-content: space-between;
    margin-left: 6px;

    div {
        position: initial;
    }
}

.segmentNodeContent {
    background: rgb(68, 79, 228);
    padding: 10px;
    border-radius: 3px;
    color: #ffffff;
    font-size: 12px;
}

.integrationNodeContent {
    background: rgb(173, 173, 0);
    padding: 10px;
    border-radius: 3px;
    color: #ffffff;
    font-size: 12px;
}

.scriptNodeContent {
    background: #ffffff;
    padding: 10px;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
}

.scoreNodeContent {
    background: rgb(48, 140, 48);
    padding: 10px;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
}


.valueSetterNodeContent {
    background: rgb(48, 57, 140);
    padding: 10px;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
}
.productCatalogsNodeContent {
    background: rgb(143, 58, 205);
    padding: 10px;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
}
