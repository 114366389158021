.version-popup-background {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    .table-popup-wrapper {

      gap: 15px;
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2%;
      min-width: 400px;
      background-color: #10111c;
      border-radius: 20px;
      box-shadow: 0 0 0 0.5px silver;
      z-index: 20;
      padding: 50px;
      .action-bar{
        display: flex;
        width: 100%;
        justify-content: end;
        img{
          cursor: pointer;
        }
      }
      .wrapper{
        min-width: 850px;
        max-height: 500px;
        overflow-y: auto;
      }
      .LogOutPanelButtons{
        width: 100%;
        display: flex;
        justify-content: space-around;
        button{
          padding: 10px 30px;
        }
      }
      h4{
        color: white;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        padding-bottom: 50px;
      }
      .logOutIcon {
        margin-bottom: 30px;
      }
    }
  }