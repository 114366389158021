.mainWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .contentWrapper {
        width: 100%;
        margin-top: 77px;
        flex: 1;
        display: flex;
        gap: 12px;
        padding: 12px 12px 12px 0px;
        background: #05050f;
        .leftSide {
            width: 368px;
            min-width: 368px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .actionsWrapper {
                button {
                    margin-bottom: 20px;
                }
            }
        }
        .rightSide {
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .folwWrapper {
                display: flex;
                height: 100%;
                position: relative;
                .folwWrapperDiv {
                    width: 100px;
                    height: 50px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 99999;
                    background: black;
                }
            }
            .codeHeader {
                display: flex;
                height: 86px;
                background: #161628;
                border-radius: 24px 24px 0 0;
                border-bottom: 1px solid #3b3c4a;
                padding: 0 24px;
                .codeHeaderNav {
                    gap: 16px;
                    flex: 1;
                    margin: auto 0;
                    display: flex;
                    align-items: center;
                    .codeHeaderTitle {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 29px;
                        color: #FFFFFF;
                    }
                }
                .codeHeaderActions {
                    width: 50%;
                    gap: 16px;
                    margin: auto 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .apiPanelWrapper{
                        .panel{
                            width: 15%;
                            background-color: #3b3c4a;
                            position: absolute;
                            right: 23%;
                            overflow: auto;
                            color: white;
                            z-index: 10;
                            border-radius: 10px;
                            .api{
                                padding: 15px 10px 15px 10px;
                                text-align: center;
                                &:hover{
                                    background-color: #05050f;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    select{
                        font-style: normal;
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 29px;
                        color: #FFFFFF;
                        border: 5px solid transparent;
                        padding: 7px 15px;
                        cursor: pointer;
                        background-color: #3b3c4a;
                        border-radius: 36px;
                        &:focus{
                            outline: none;
                        }
                        select > option{
                            cursor: pointer;
                            padding: 10px;
                        }
                    }
                    .addIcon{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
