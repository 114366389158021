.roles-page{
  flex-direction: column;
  label{
    max-width: 30%;
  }
  .infoList{
    display: flex;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 50px;
    .infoListRole {
      flex-basis: 26%;
      gap: 10px;
      margin-right: 100px;
    }
  }

}
