.leftSide {
    width: 368px;
    min-width: 368px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .actionsWrapper {
        button {
            margin-bottom: 20px;
        }
    }
}
::-webkit-scrollbar {
    display: none;
}
.sidebarWrapper {
    display: flex;
    background: #05050f;
    width: 300px;
    .sidebar {
        height: calc(100vh - 101px);
        border-radius: 0 16px 16px 0;
        background: #10111c;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(255, 255, 255, 0.08);
        border-left: none;
        position: fixed;
        width: 300px;
        top: 90px;
        overflow: auto;
        justify-content: space-between;
        
        .sidebarTitle {
            padding: 32px 35px;
            border-bottom: 1px solid #319b69;
            span {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.04em;
                color: #ffffff;
                margin-left: 15px;
            }
            img {
                vertical-align: middle;

            }
        }
        .sidebarMenu {
            padding: 10px;
            ul {
                list-style-type: none;
                li {
                    border: 1px solid transparent;
                    cursor: pointer;
                    margin-bottom: 4px;
                    a {
                        padding: 12px 12px;
                        text-decoration: none;
                        display: block;
                    }
                    img {
                        vertical-align: middle;
                        width: 20px;
                    }
                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 1;
                        letter-spacing: 0.04em;
                        color: #ffffff;
                        margin-left: 20px;
                    }
                }
                li.active {
                    background: linear-gradient(
                        93.3deg,
                        rgba(0, 191, 87, 0.22) 1.31%,
                        rgba(0, 93, 105, 0.22) 98.88%,
                        rgba(0, 105, 86, 0.22) 98.88%
                    );
                    border: 1px solid rgba(0, 198, 162, 0.3);
                    border-radius: 12px;
                }
                li:hover {
                    background: linear-gradient(
                        93.3deg,
                        rgba(0, 191, 87, 0.22) 1.31%,
                        rgba(0, 93, 105, 0.22) 98.88%,
                        rgba(0, 105, 86, 0.22) 98.88%
                    );
                    border: 1px solid rgba(0, 198, 162, 0.3);
                    border-radius: 12px;
                }
            }
        }
        .sidebaLogout {
            padding: 12px 12px;
            margin: 0 18px 70px 18px;
            ul {
                li {
                    cursor: pointer;
                    list-style-type: none;
                    img {
                        vertical-align: middle;
                    }
                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 27px;
                        letter-spacing: 0.04em;
                        color: #ffffff;
                        margin-left: 20px;
                    }
                }
            }
        }
        .version {
            span {
                display: inline-block;
                padding: 5px;
                font-size: 12px;
                color: #fff;
            }
        }
    }
}

$layout-breakpoint-small: 970px;

@media (max-width: $layout-breakpoint-small) {
  .sidebar{
    position: fixed;
    top: 163px;
  }
}