.closeWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
}
.noneWrapper{
    display: none;
}