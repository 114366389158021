.formInput {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    display: inline-block;
    width: 100%;
    input {
        height: 52px;
        background: rgba(90, 90, 137, 0.76);
        box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
        border-radius: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        opacity: 0.6;
        padding: 10px 20px;
        border: none;
        width: 100%;
        // margin-top: 8px;
    }
    input:invalid {
        border: 1px solid red;
      }
}

