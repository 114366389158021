

.navigationBar  {
    margin-right: 16px;
    margin-left: 16px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: end;
    span{
        display: flex;
        color: white;
        margin-left: 2px;
        margin-right: 2px;
        font-size: 13px;
        font-weight: 700;
    }
    div{
        margin-left: 6px;
        margin-right: 6px;
    }
    svg{
        color: white;
    }
}