.mainWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .contentWrapper {
        width: 100%;
        margin-top: 77px;
        flex: 1;
        display: flex;
        gap: 12px;
        padding: 12px 12px 12px 0px;
        background: #05050f;
        .tableWrapper {
            gap:12px;
            flex: 1;
        }
    }
}
