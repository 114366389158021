.formButton {
    background: linear-gradient(93.3deg, rgba(1, 233, 107, 0.4) 1.31%, rgba(0, 93, 105, 0.4) 98.88%);
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding: 16px;
    width: 100%;
    cursor: pointer;
}
.formButtonRed {
    background: linear-gradient(93.3deg, rgba(255, 71, 71, 0.4) 1.31%, rgba(132, 15, 15, 0.4) 98.88%);
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding: 16px;
    width: 100%;
    cursor: pointer;
}