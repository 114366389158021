.formButton {
    background: none;
    border-radius: 36px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    border: none;
    padding: 15px 24px;
    cursor: pointer;
}