
.popupWrapper {
    background: rgba(5, 5, 15, 0.6);
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    top:0;
    left: 0;
    .popupBox {
        background: #121222;
        border-radius: 36px;
        width: 1134px;
        margin: auto;
        border: 1px solid #282836;
        padding: 52px 52px 48px 52px;
        .popupHeader {
            display: flex;
            margin-bottom: 36px;
            .popupName {
                flex: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 39px;
                color: #FFFFFF;
            }
            .popupX {
                margin: auto;
                img {
                    cursor: pointer;
                }
            }
        }
        .popupForm {
            padding-bottom: 28px;
            .popupFormItem {
                margin-bottom: 24px;
            }
        }
        .popupContent {
            margin-bottom: 52px;
            .popupResponse {
                margin-bottom: 16px;
                .popupResponseTitle {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                    margin-bottom: 8px;
                }
                .popupResponseText {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    color: rgba(225, 225, 225, 0.8);
                    background: rgba(255, 255, 255, 0.04);
                    border-radius: 8px;
                    padding: 16px;
                    max-height: 220px;
                    overflow: auto;
                }
            }
            .popupRequest {
                .popupRequestTitle {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                    margin-bottom: 8px;
                }
                .popupRequestText {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    color: rgba(225, 225, 225, 0.8);
                    background: rgba(255, 255, 255, 0.04);
                    border-radius: 8px;
                    padding: 16px;
                    max-height: 220px;
                    overflow: auto;
                }
            }
        }
        .popupButtonsWrapper {
            display: flex;
            gap: 16px;
            width: 351px;
            margin-left: auto;
        }
    }
}