.formButton {
    border-radius: 12px;
    border: 1px solid #666671;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding: 16px;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
}