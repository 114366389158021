.sidebarWrapper {
    display: flex;
    height: 100%;
    background: #05050f;
    width: 340px;
    .sidebar {
        height: 100%;
        width: 100%;
        border-radius: 0 36px 36px 0;
        background: linear-gradient(170.13deg, rgba(1, 233, 107, 0.6) 14.82%, rgba(0, 93, 105, 0.6) 85.18%);
        display: flex;
        flex-direction: column;
        .sidebarTitle {
            padding: 32px 35px;
            border-bottom: 1px solid #319b69;
            span {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                margin-left: 15px;
            }
            img {
                vertical-align: middle;
            }
        }
        .sidebarMenu {
            padding: 33px 18px;
            ul {
                list-style-type: none;
                li {
                    padding: 12px 12px;
                    cursor: pointer;
                    a {
                        text-decoration: none;
                    }
                    img {
                        vertical-align: middle;
                    }
                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 27px;
                        letter-spacing: 0.04em;
                        color: #FFFFFF;
                        margin-left: 20px;
                    }
                }
                li:hover {
                    background: #35806a;
                    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
                    border-radius: 12px;
                }
            }
        }
        .sidebaLogout {
            padding: 12px 12px;
            margin: auto 18px 70px 18px;
            ul {
                li {
                    cursor: pointer;
                    list-style-type: none;
                    img {
                        vertical-align: middle;
                    }
                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 27px;
                        letter-spacing: 0.04em;
                        color: #FFFFFF;
                        margin-left: 20px;
                    }
                }
            }
            
        }
    }
}