.single-page-content-header {
  display: flex;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  background: #10111C;

  .title {
    flex: 1 1;
    margin: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
  }

  .actions {
    gap: 15px;
    display: flex;
  }
}

