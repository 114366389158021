.contentWrapper {
    gap: 8px;

    .rightSide {
        flex: 1;
        overflow: auto;
        zoom: 0.8;

        .codeWrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            border: 1px solid #2e2e38;
            box-shadow: 0 2px 6px rgb(51 51 51 / 11%);
            border-radius: 16px;
            overflow: hidden;

            .script-content {
                flex: 1;
            }

            .segment-content {
                padding: 48px 24px 24px 24px;
                flex: 1;
                display: flex;
                flex-direction: column;

                .formHeader {
                    max-width: 446px;
                    margin-bottom: 48px;
                }

                .formBody {
                    border-top: 1px solid rgba(255, 255, 255, 0.16);
                    padding-top: 28px;

                    .formBodyLabels {
                        display: flex;
                        gap: 24px;

                        div {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            color: #ffffff;
                            margin-bottom: 16px;
                            flex: 1;
                        }
                    }

                    .formWrapper {
                        .formItem {
                            display: flex;
                            gap: 24px;
                        }

                        .removeSegment {
                            color: red;
                            font-size: 45px;
                            cursor: pointer;
                            padding: 0 10px;
                        }
                    }
                }

                .addFormItem {
                    text-align: right;
                    margin-top: auto;

                    img {
                        cursor: pointer;
                    }
                }
            }

            .score-content {
                padding: 48px 24px 24px 24px;
                flex: 1 1;
                display: flex;
                flex-direction: column;

                .mainForm {
                    border-bottom: 1px solid #fff;
                    margin-bottom: 20px;

                    .mainFormContent {
                        width: 300px;

                        .formItem {
                            margin-bottom: 20px;
                        }
                    }
                }

                .formSegment {
                    .segmentSection {
                        border-bottom: 1px solid #fff;
                        margin-bottom: 20px;
                        padding-bottom: 20px;

                        .selectWrapper {
                            display: flex;

                            .selectButtonWrapper {
                                max-width: 300px;
                                flex: 1;
                            }

                            .selectRemoveWrapper {
                                margin-left: auto;
                            }
                        }

                        .formItem {
                            display: flex;
                            gap: 24px;
                            margin: 15px 0;
                        }
                    }

                    .addButton {
                        text-align: right;
                    }
                }

                .formResult {
                    .resultWrapper {
                        margin-bottom: 20px;

                        .formItem {
                            display: flex;
                            gap: 24px;
                            margin: 15px 0;

                            .remove {
                                color: red;
                                font-size: 45px;
                                cursor: pointer;
                                padding: 21px 10px 1px;
                            }
                        }
                    }

                    .resultTitle {
                        color: #fff;
                        font-size: 24px;
                    }

                    .addButton {
                        text-align: right;
                    }
                }
            }

            .value-setter-content {
                .formHeaderActions {
                    background: #1e1f2a;
                    display: flex;
                    border-bottom: 1px solid #2f303a;

                    .formHeaderSelect {
                        margin: 24px;
                        width: 320px;
                    }

                    .formHeaderButton {
                        margin: auto 24px 24px auto;
                    }
                }

                .formTableWrapper {
                    overflow: auto;

                    .formTableHeader {
                        padding: 11px 0;
                        background: #1a1b25;
                        border-bottom: 1px solid #2f303a;
                        display: flex;

                        .formTableVariableNameWrapper {
                            display: flex;
                            width: 232px;
                            min-width: 232px;

                            .nameLine {
                                background: rgba(255, 255, 255, 0.12);
                                width: 1px;
                                height: 12px;
                                margin: auto 12px;
                            }

                            .formTableVariableName {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: #ffffff;
                                border: 1px solid rgba(255, 255, 255, 0.16);
                                border-radius: 9px;
                                height: 32px;
                                margin: auto;
                                padding: 6px 12px;
                                margin-left: 0;

                                img {
                                    width: 16px;
                                    height: 16px;
                                    margin-left: 8px;
                                    margin-bottom: -2px;
                                    cursor: pointer;
                                }
                            }
                        }

                        .formTableSegmentName {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: #ffffff;
                            padding: 9px;
                            margin-left: 69px;
                            width: 275px;
                            min-width: 275px;
                        }
                    }

                    .formTable {
                        background: #1a1b25;

                        .formTableRowWrapper {
                            .formTableRowChildren {
                                margin-left: 60px;

                                .formTableHeader {
                                    display: flex;

                                    .formTableVariableNameWrapper {
                                        .formTableVariableName {
                                            border: none;
                                        }
                                    }

                                    .formTableSegmentName {
                                        margin-left: 0;
                                        width: 260px;
                                        min-width: 260px;
                                    }

                                    .formTableHeaderTrash {
                                        margin: auto 30px;

                                        img {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            .formTableRow {
                                background: #151621;
                                border-bottom: 1px solid #2f303a;
                                display: flex;
                                flex-direction: row;
                                padding: 16px 12px;
                                gap: 12px;

                                .formTableInput {
                                    width: 220px;
                                    min-width: 220px;
                                }

                                .formTableMainCell {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 12px;

                                    .arrowDown {
                                        width: 52px;
                                        min-width: 52px;
                                        margin: auto;
                                        text-align: center;

                                        img {
                                            width: 36px;
                                            max-width: 100%;
                                            cursor: pointer;
                                            animation-timing-function: linear;
                                        }

                                        .arrowRight {
                                            transform: rotate(-90deg);
                                        }
                                    }

                                    .pluseBtn {
                                        background: transparent;
                                        border: 1px solid rgba(0, 198, 162, 0.7);
                                        border-radius: 12px;
                                        box-shadow: 0px 2px 6px
                                            rgba(51, 51, 51, 0.11);
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 20px;
                                        letter-spacing: 0.04em;
                                        color: #ffffff;
                                        padding: 15px 17px;
                                        cursor: pointer;

                                        img {
                                            width: 16px;
                                            height: 16px;
                                            vertical-align: middle;
                                        }
                                    }

                                    .formTableMainInput {
                                        width: 268px;
                                        min-width: 208px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .integration-content {
                .codeContent {
                    flex: 1;
                    gap: 16px;
                    width: 100%;

                    .codeContentTitle {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.04em;
                        color: #ffffff;
                        padding: 10px 10px 0 10px;
                    }

                    .fileTypes {
                        width: 11%;
                        margin-left: 5%;

                        label {
                            margin: 10px;
                        }

                        .selectApiType {
                            width: 11%;
                            margin: 15px;
                            padding: 10px;
                            background-color: #161628;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 600;
                            border-radius: 10px;
                            border: 5px solid transparent;
                            cursor: pointer;

                            .apiOptions {
                                padding: 10px;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 500;
                            }

                            :focus {
                                outline: none;
                            }
                        }
                    }

                    .codeContent_left {
                        display: flex;
                        width: 60%;
                        margin-left: 5%;

                        label {
                            margin: 10px;
                        }

                        .apiType {
                            display: flex;
                            align-items: center;
                            width: 28%;
                        }

                        .selectApiType {
                            width: 11%;
                            margin: 15px;
                            padding: 10px;
                            background-color: #161628;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 600;
                            border-radius: 10px;
                            border: 5px solid transparent;
                            cursor: pointer;

                            .apiOptions {
                                padding: 10px;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 500;
                            }

                            :focus {
                                outline: none;
                            }
                        }

                        .selectUrl {
                            width: 25%;
                            margin: 15px;
                            padding: 10px;
                            background-color: #161628;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 600;
                            border-radius: 10px;
                            border: 5px solid transparent;
                        }
                    }

                    .codeContent_right {
                        width: 100%;
                        display: flex;
                        justify-content: start;

                        label {
                            margin: 0 10px 0 10px;
                        }

                        .formBody {
                            width: 100%;
                            display: flex;
                            justify-content: start;
                            padding-top: 5px;
                            margin-left: 5%;

                            .formBodyLabels {
                                width: 100%;
                                display: flex;
                                gap: 24px;
                                align-items: center;

                                div {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: #ffffff;
                                    margin-bottom: 16px;
                                    flex: 1;
                                }
                            }

                            .formWrapper {
                                width: 68%;

                                .keyTitle {
                                    display: flex;
                                    align-items: center;
                                    color: white;
                                    width: 18%;
                                    margin-left: 10px;
                                }

                                .formItem {
                                    display: flex;
                                    gap: 24px;
                                }

                                .removeSegment {
                                    color: red;
                                    font-size: 45px;
                                    cursor: pointer;
                                    padding: 0 10px;
                                }
                            }
                        }
                    }
                }

                .addFormItem {
                    text-align: right;
                    margin-top: auto;

                    img {
                        cursor: pointer;
                    }
                }

                .monacoField {
                    width: 100%;
                    height: 500px;
                    padding: 10px;
                    margin: 20px 0 20px 0;

                    .monacoTitle {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 25px;
                        line-height: 20px;
                        margin: 10px;
                        color: white;
                    }
                }

                .formContent {
                    padding: 48px 24px 24px 24px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .formHeader {
                        max-width: 446px;
                        margin-bottom: 48px;
                    }

                    .formBody {
                        border-top: 1px solid rgba(255, 255, 255, 0.16);
                        padding-top: 28px;

                        .formBodyLabels {
                            display: flex;
                            gap: 24px;

                            div {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                color: #ffffff;
                                margin-bottom: 16px;
                                flex: 1;
                            }
                        }

                        .formWrapper {
                            .formItem {
                                display: flex;
                                gap: 24px;
                            }
                        }
                    }

                    .addFormItem {
                        text-align: right;
                        margin-top: auto;

                        img {
                            cursor: pointer;
                        }
                    }
                }
            }

            .integration-db-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                border: 1px solid #2e2e38;
                box-shadow: 0px 2px 6px rgb(51 51 51 / 11%);
                //border-radius: 24px;
                overflow: hidden;

                .single-table-wrapper {
                    flex-direction: column;
                    align-items: start;
                    gap: 12px;
                    top: 190px;
                    overflow: auto;
                    flex: 1;
                    height: 100vh;
                    border-radius: 16px 16px 0 0;
                    padding: 10px;

                    .codeHeader {
                        display: flex;
                        height: 86px;
                        background: #161628;
                        border-radius: 24px 24px 0 0;
                        border-bottom: 1px solid #3b3c4a;
                        padding: 0 24px;

                        .codeHeaderNav {
                            gap: 16px;
                            flex: 1;
                            margin: auto 0;
                            display: flex;

                            .codeHeaderTitle {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 29px;
                                color: #ffffff;
                            }
                        }

                        .codeHeaderActions {
                            gap: 16px;
                            margin: auto 0;
                            display: flex;
                        }
                    }

                    .monacoField {
                        width: 100%;
                        height: 500px;
                        padding: 10px;
                        margin: 20px 0 20px 0;

                        .monacoTitle {
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 25px;
                            font-weight: 500;
                            float: left;
                            margin-top: 50px;
                        }
                    }

                    .row {
                        display: flex;
                        width: 40%;
                        flex-direction: column;
                        align-items: start;
                        margin: 10px;

                        span {
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            float: left;
                            width: 100%;
                        }

                        .inputTitle {
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            float: left;
                            width: 75%;
                        }
                    }

                    .dbTitle {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 25px;
                        color: white;
                        font-weight: 500;
                        margin: 10px;
                    }

                    .inputsWrapper {
                        width: 80%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        label {
                            width: 75%;
                        }
                    }
                }

                .codeHeader {
                    display: flex;
                    height: 86px;
                    background: #161628;
                    border-radius: 24px 24px 0 0;
                    border-bottom: 1px solid #3b3c4a;
                    z-index: 2;
                    padding: 0 24px;

                    .codeHeaderNav {
                        gap: 16px;
                        flex: 1;
                        margin: auto 0;
                        display: flex;

                        .codeHeaderTitle {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 29px;
                            color: #ffffff;
                        }
                    }

                    .codeHeaderActions {
                        gap: 16px;
                        margin: auto 0;
                        display: flex;
                    }
                }

                .codeContent {
                    flex: 1;
                    gap: 16px;
                    width: 100%;

                    .codeContentTitle {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.04em;
                        color: #ffffff;
                        padding: 10px 10px 0 10px;
                    }

                    .fileTypes {
                        width: 10%;
                        margin-left: 5%;

                        label {
                            margin: 10px;
                        }

                        .selectApiType {
                            width: 10%;
                            margin: 15px;
                            padding: 10px;
                            background-color: #161628;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 600;
                            border-radius: 10px;
                            border: 5px solid transparent;
                            cursor: pointer;

                            .apiOptions {
                                padding: 10px;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 500;
                            }

                            :focus {
                                outline: none;
                            }
                        }
                    }

                    .codeContent_left {
                        display: flex;
                        width: 60%;
                        margin-left: 5%;

                        label {
                            margin: 10px;
                        }

                        .apiType {
                            display: flex;
                            align-items: center;
                            width: 27%;
                        }

                        .selectApiType {
                            width: 10%;
                            margin: 15px;
                            padding: 10px;
                            background-color: #161628;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 600;
                            border-radius: 10px;
                            border: 5px solid transparent;
                            cursor: pointer;

                            .apiOptions {
                                padding: 10px;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 500;
                            }

                            :focus {
                                outline: none;
                            }
                        }

                        .selectUrl {
                            width: 25%;
                            margin: 15px;
                            padding: 10px;
                            background-color: #161628;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 600;
                            border-radius: 10px;
                            border: 5px solid transparent;
                        }
                    }

                    .codeContent_right {
                        width: 100%;
                        display: flex;
                        justify-content: start;

                        label {
                            margin: 0 10px 0 10px;
                        }

                        .formBody {
                            width: 100%;
                            display: flex;
                            justify-content: start;
                            padding-top: 5px;
                            margin-left: 5%;

                            .formBodyLabels {
                                width: 100%;
                                display: flex;
                                gap: 24px;
                                align-items: center;

                                div {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: #ffffff;
                                    margin-bottom: 16px;
                                    flex: 1;
                                }
                            }

                            .formWrapper {
                                width: 70%;

                                .keyTitle {
                                    display: flex;
                                    align-items: center;
                                    color: white;
                                    width: 18%;
                                    margin-left: 10px;
                                }

                                .formItem {
                                    display: flex;
                                    gap: 24px;
                                }

                                .removeSegment {
                                    color: red;
                                    font-size: 45px;
                                    cursor: pointer;
                                    padding: 0 10px;
                                }
                            }
                        }
                    }
                }

                .addFormItem {
                    text-align: right;
                    margin-top: auto;

                    img {
                        cursor: pointer;
                    }
                }

                .monacoField {
                    height: 500px;
                    padding: 10px;
                    margin: 20px 0 20px 0;

                    .monacoTitle {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 25px;
                        line-height: 20px;
                        margin: 10px;
                        color: white;
                    }
                }

                .formContent {
                    padding: 48px 24px 24px 24px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .formHeader {
                        max-width: 446px;
                        margin-bottom: 48px;
                    }

                    .formBody {
                        border-top: 1px solid rgba(255, 255, 255, 0.16);
                        padding-top: 28px;

                        .formBodyLabels {
                            display: flex;
                            gap: 24px;

                            div {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                color: #ffffff;
                                margin-bottom: 16px;
                                flex: 1;
                            }
                        }

                        .formWrapper {
                            .formItem {
                                display: flex;
                                gap: 24px;
                            }
                        }
                    }

                    .addFormItem {
                        text-align: right;
                        margin-top: auto;

                        img {
                            cursor: pointer;
                        }
                    }
                }
            }

            .data-model-content {
                .codeContent {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .codeContentTitle {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.04em;
                        color: #ffffff;
                        padding: 10px 10px 0 10px;
                    }

                    .monaco {
                        height: 300px;
                    }
                }
            }

            .parameter-content {
                .inputsWrapper {
                    flex-basis: 40%;
                    .row {
                        margin-top: 20px;
                        label {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            color: #ffffff;
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }
                &.parameter-individual-page {
                    max-width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .historyWrapper {
                        flex-basis: 55%;
                        padding: 30px 0;
                        .title {
                            flex: 1 1;
                            margin: auto;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 29px;
                            color: #ffffff;
                        }
                    }
                }
                max-width: 600px;
                padding: 0 20px;
                textarea {
                    background: rgba(90, 90, 137, 0.76);
                    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
                    border-radius: 12px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    color: #ffffff;
                    opacity: 0.6;
                    padding: 10px 20px;
                    border: none;
                    width: 100%;
                }
            }

            .rule-content {
                .inputsWrapper {
                    width: 100%;
                    padding: 0 20px;
                    .remove {
                        color: red;
                        font-size: 45px;
                        cursor: pointer;
                        padding: 21px 10px 1px;
                    }
                    .row {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                        label {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            color: #ffffff;
                            display: inline-block;
                            width: 100%;
                            margin-right: 10px;
                        }
                        &.second-row {
                            .resultTitle {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 20px;
                                color: #ffffff;
                                display: inline-block;
                                width: 100%;
                                margin-bottom: 20px;
                            }
                            .conditions-block {
                                flex-basis: 64%;
                                display: flex;
                                flex-direction: column;
                                .remove {
                                    color: red;
                                    font-size: 45px;
                                    cursor: pointer;
                                    padding: 21px 10px 1px;
                                }
                                .conditions {
                                    width: 100%;
                                    border: 1px solid rgba(255, 255, 255, 0.16);
                                    border-radius: 4px;
                                    padding: 10px;
                                    margin-bottom: 20px;
                                    .resultWrapper {
                                        margin-bottom: 10px;
                                        .formItem {
                                            display: flex;
                                            justify-content: space-between;
                                            margin: 10px 0;
                                            .firstIf {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                height: 100%;
                                                padding-top: 25px;
                                                font-weight: bold;
                                                color: #ffffff;
                                            }
                                            label {
                                                &:first-child {
                                                    flex-basis: 12%;
                                                }
                                                flex-basis: 27%;
                                            }
                                            &.first-child {
                                                justify-content: flex-end;
                                                label {
                                                    margin-left: 10px;
                                                    &:first-child {
                                                        flex-basis: 27%;
                                                    }
                                                    flex-basis: 27%;
                                                }
                                            }
                                        }
                                    }
                                }
                                .conditionGroupButton {
                                    float: right;
                                }
                                .results {
                                    margin-top: 30px;
                                    .formItem {
                                        display: flex;
                                        justify-content: space-between;
                                        label {
                                            flex-basis: 45%;
                                        }
                                    }
                                }
                            }
                            label {
                                flex-basis: 35%;
                                margin-right: 0;
                            }
                        }
                    }

                    .rule-parameters {
                        margin-bottom: 30px;
                        .conditions{
                            padding: 0 !important;
                            border:0 !important;
                            .formItem{
                                display: flex;
                                align-items: center;
                                   label {
                                       margin-right: 5px;
                                       flex-basis:45%;
                                   }
                                   .remove{
                                       padding: 1px 10px 1px !important;
                                   }
                           }
                        }
                    }
                }
                textarea {
                    background: rgba(90, 90, 137, 0.76);
                    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.11);
                    border-radius: 12px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    color: #ffffff;
                    opacity: 0.6;
                    padding: 10px 20px;
                    border: none;
                    width: 100%;
                }

                .catalog-main-content {
                    display: flex;
                    justify-content: space-between;

                    .rules{
                        flex-basis: 48%;
                        .conditions {
                            width: 100%;
                            margin-bottom: 10px;
                            .formItem {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                label {
                                    flex-basis: inherit;
                                }
                                .remove{
                                    padding-top: 0px;
                                }
                            }
                        }
                    }
                    .parameters{
                        flex-basis: 48%;
                        .conditions {
                            width: 100%;
                            margin-bottom: 10px;
                            .formItem {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                label {
                                    flex-basis: 44%;
                                }
                                .remove{
                                    padding-top: 0px;
                                }
                            }
                        }
                    }


                }
                .sub-catalog-main-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .catalog-first-row{
                        display: flex;
                        .parameters{
                            flex-basis: 48%;
                            .conditions {
                                width: 100%;
                                margin-bottom: 10px;
                                .formItem {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    label {
                                        flex-basis: 44%;
                                    }
                                    .remove{
                                        padding-top: 0px;
                                    }
                                }
                            }
                        }

                    }

                    .catalog-second-row{
                        display: flex;
                        .rules{
                            flex-basis: 48%;
                            max-width: 48%;
                            margin-top: 30px;
                            .conditions {
                                width: 100%;
                                margin-bottom: 10px;
                                .formItem {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    label {
                                        flex-basis: inherit;
                                    }
                                    .remove{
                                        padding-top: 0px;
                                    }
                                }
                            }
                        }
                        .calculation-blocks{
                            flex-basis: 48%;
                            margin-bottom: 30px;
                            margin-top: 30px;
                            .conditions {
                                width: 100%;
                                margin-bottom: 10px;
                                .formItem {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    label {
                                        flex-basis: 90%;
                                    }
                                    .remove{
                                        padding-top: 0px;
                                    }
                                }
                            }
                        }
                    }


                }
            }

            .user-page {
                .second-row {
                    justify-content: inherit !important;
                    align-items: center;
                    div {
                        margin-top: 27px;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}
